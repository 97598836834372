import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import GImage from "../../../gatsby/GImage"
import { ImageType } from "social-supermarket-model"

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  outline: none;
  box-sizing: border-box;
  border: ${({ inFocus, focusable }) =>
    inFocus ? "2px solid #99ddd8" : focusable ? "2px solid rgb(0,0,0,0)" : "none"};

  cursor: ${({ onClick }) => (onClick ? "pointer" : "inherit")};

  .tss-image-primary {
    opacity: 1;
  }
  .tss-image-secondary {
    opacity: 0;
  }

  &:hover {
    .tss-image-primary {
      opacity: ${({ hasSecond }) => (hasSecond ? "0" : "1")};
    }
    .tss-image-secondary {
      opacity: 1;
    }
  }
`

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface Props {
  image: ImageType
  secondImage?: ImageType
  onClick?: () => void
  inFocus?: boolean
  focusable?: boolean
  objectFit?: "cover" | "contain"
  className?: string
  imageBackground?: string
}

const SquareImage: FC<Props> = ({
  image,
  secondImage,
  onClick,
  inFocus,
  focusable,
  objectFit = "contain",
  className,
  imageBackground,
}) => {
  return (
    <Container
      hasSecond={secondImage}
      onClick={onClick}
      inFocus={inFocus}
      focusable={focusable}
      className={className}
    >
      {image && (
        <ImageContainer className="tss-image-primary">
          <GImage
            image={image}
            style={{ height: "100%", width: "100%", backgroundColor: imageBackground }}
            objectFit={objectFit}
          />
        </ImageContainer>
      )}
      {secondImage && (
        <ImageContainer className="tss-image-secondary">
          <GImage
            image={secondImage}
            style={{ height: "100%", width: "100%", backgroundColor: imageBackground }}
            objectFit={objectFit}
          />
        </ImageContainer>
      )}
    </Container>
  )
}

export default SquareImage
