import * as React from "react"
import { FC, useContext, useState } from "react"
import styled from "styled-components"
import useQueryString from "../../../hooks/useQueryString"
import { GiftListContext } from "../../../context/GiftListProvider"
import {
  FullLinkButton,
  IconWrapper,
  LoginContext,
  Spinner,
} from "social-supermarket-components"
import { navigate } from "gatsby"
import * as orderApi from "../../../api/platform/orderApi"
import SubMenuBar from "../../../components/header/SubMenuBar"
import { isFullySelected } from "../../../util/productUtil"
import { Icon } from "semantic-ui-react"
import GiftListSidebar from "../catalogue/GiftListSidebar"
import ProductFilterList from "../../../components/product/ProductFilterList"
import { GIFTING_FILTER_NODES } from "../../../components/catalogue/filters/category-tree-filter/filterNodes"
import ProductGiftModal from "../catalogue/ProductGiftModal"
import { ProductType } from "social-supermarket-model"

const Container = styled.div`
  padding-top: 45px;
`

const BackButton = styled(FullLinkButton)`
  margin-right: 10px;
`

const StyledSubMenuBar = styled(SubMenuBar)`
  padding: 10px;
  box-shadow: rgb(230 230 230) 0px -1px 0px 0px inset;
  font-size: 0.9em;
  display: flex;
  align-items: center;
`

const CatalogueContainer = styled.div`
  display: flex;
`
const SideBarContainer = styled.div`
  width: ${({ visible }) => (visible ? "60px" : "0")};
  transition: all 0.2s ease-in-out;
`
const StyledProductFilterList = styled(ProductFilterList)`
  padding: ${({ sidebarVisible }) => (sidebarVisible ? "10px" : "10px 40px")};
  transition: all 0.2s ease-in-out;
  flex: 1;
`

interface Props {}

const GiftListUpdater: FC<Props> = () => {
  const { token } = useContext(LoginContext)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [key, setKey] = useQueryString("key", "")
  const { choices, cancel, updateGiftList, allGiftLists, addChoices } = useContext(GiftListContext)
  const [modalProduct, setModalProduct] = useState<ProductType>(null)

  const thisGiftList = allGiftLists ? allGiftLists.find(giftList => giftList.key === key) : null

  const giftPageSidebarVisible = choices.length > 0

  const handleSubmit = async () => {
    try {
      setIsUpdating(true)
      const order = await orderApi.updateOptions(token, key, choices)
      updateGiftList(order)
      navigate(`/gifting/gift-list?key=${order.key}`)
      cancel()
    } catch (e) {
      console.log(`Unexpected error, please contact support: ${e}`)
    } finally {
      setIsUpdating(false)
    }
  }

  const handleBack = () => {
    cancel()
    navigate(`/gifting/gift-list?key=${key}`)
  }

  const handleAddToList = (product: ProductType) => {
    if (product.variants) {
      setModalProduct(product)
    } else {
      addChoices([{ product, variant: null }])
    }
  }

  const addControls = [
    {
      getLabel: product =>
        isFullySelected(product, choices, null) ? <Icon name={"check"} /> : "Add To Gift Page",
      isDisabled: product => isFullySelected(product, choices, null),
      onClick: handleAddToList,
    },
  ]

  return (
    <Container>
      <StyledSubMenuBar>
        <BackButton onClick={handleBack}>
          <IconWrapper name="angle left" />
          Back
        </BackButton>
        {thisGiftList && (
          <div>
            <b>Updating:</b> {thisGiftList.name}
          </div>
        )}
      </StyledSubMenuBar>
      <Spinner isLoading={isUpdating} label={"Updating Gift Page Options.."} />
      <CatalogueContainer>
        <StyledProductFilterList
          category="GIFTING"
          sidebarVisible={giftPageSidebarVisible}
          primaryControls={addControls}
          secondaryControls={[]}
          onClick={product => setModalProduct(product)}
          showFilters
          categoryFilterNodes={GIFTING_FILTER_NODES}
        />
        <SideBarContainer visible={giftPageSidebarVisible}>
          <GiftListSidebar
            onSubmit={handleSubmit}
            onCancel={handleBack}
            submitLabel={"Update List"}
          />
        </SideBarContainer>
      </CatalogueContainer>

      <ProductGiftModal
        productId={modalProduct?.productId}
        show={!!modalProduct}
        onClose={() => setModalProduct(null)}
        priceIncludesShipping={false}
      />
    </Container>
  )
}

export default GiftListUpdater
